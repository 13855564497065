/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

          var docHeight = $(window).height();
          var footerHeight = $('#footer').height();
          var footerTop = $('#footer').position().top + footerHeight;

          if (footerTop < docHeight) {
              $('#footer').css('margin-top', 10+ (docHeight - footerTop) + 'px');
          }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        // pull slider from wrapped content area so it goes fullwidth
        $("#cycloneslider-homepage-1").append(
          '<a title="More…" class="scrollto" href="#main"><span></span></a>'
        );
        $("#cycloneslider-homepage-1").detach().insertAfter('.navbar-default');
        $("body > .navbar-fixed-top + .wrap").css({'margin-top': 0 });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $('a.scrollto').on('click', function (e) {
            e.preventDefault();
            // scroll to position of top of main content area, less navbar height, to compensate for how it overlays content area
            $('html, body').animate({ scrollTop: ( $('.main').closest('.wrap').offset().top - $('.navbar').height() ) }, 1000 );
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
